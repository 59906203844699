import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import userStore from '../../stores/userStore';
import { authenticateWithInitData, setNewPassword } from '../../http/telegramAuth';
import WebApp from '@twa-dev/sdk';
import $api from '../../http';
import { useNavigate } from 'react-router-dom';
import './Login.css';
const Login = observer(() => {
    const [error, setError] = useState(null);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isNewUser, setIsNewUser] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (isLoggedIn) {
            navigate('/dashboard');
        }
    }, [isLoggedIn]); 

    useEffect(() => {
        const handleLogin = async () => {
            try {
                const response = await authenticateWithInitData(WebApp.initData);
                if (response.needPassword) {
                    setIsNewUser(false);
                } else if (response.newUser) {
                    setIsNewUser(true);
                } else {
                    const user = WebApp.initDataUnsafe?.user;
                    await userStore.setTokens(response.accessToken, response.refreshToken);
                    userStore.setUser(user);
                    // Проверка верификации пользователя
                    /*const responseCheck = await $api.get('/check-verification');
                    const data = responseCheck.data;
                    if (data?.isVerified) {
                        console.log('Пользователь верифицирован');
                    } else {
                        console.log('Пользователь не верифицирован');
                    }*/
                }
            } catch (error) {
                console.error('Login failed', error);
                setError('Ошибка при входе');
            }
        };

        handleLogin();
    }, []);

    const handlePasswordSubmit = async () => {
        if (isNewUser && password !== confirmPassword) {
            setError('Пароли не совпадают');
            return;
        }

        try {
            const response = isNewUser
                ? await setNewPassword(WebApp.initData, password)
                : await authenticateWithInitData(WebApp.initData, password);

            if (response.success) {
                const user = WebApp.initDataUnsafe?.user;
                await userStore.setTokens(response.accessToken, response.refreshToken);
                userStore.setUser(user);
                setIsLoggedIn(true);
            } else {
                setError('Неверный пароль или ошибка авторизации');
            }
        } catch (error) {
            console.error('Password submission failed', error);
            setError('Ошибка при отправке пароля');
        }
    };

    return (
        <div className='login-container'>
            <h2 className='header'>{isNewUser ? 'Создание пароля' : 'Вход'}</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Пароль"
            />
            {isNewUser && (
                <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Подтвердите пароль"
                />
            )}
            <button onClick={handlePasswordSubmit}>
                {isNewUser ? 'Создать пароль' : 'Войти'}
            </button>
        </div>
    );
});

export default Login;