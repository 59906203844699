import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import userStore from './stores/userStore';
import Login from './components/Login/Login';
import EmptyScreen from './components/EmptyScreen/EmptyScreen';
import Dashboard from './components/Dashboard/Dashboard';
import Statistics from './components/Statistics/Statistics';
import Management from './components/Management/Management';
import Orders from './components/Orders/Orders';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import './App.css';

const App = observer(() => {
    const [menuVisible, setMenuVisible] = useState(true);
    const appRef = useRef(null);
    const [loading, setLoading] = useState(true);

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    useEffect(() => {
        const initializeApp = async () => {
            try {
                await userStore.checkAuthentication();
            } catch (error) {
                console.error('Ошибка при инициализации приложения', error);
            } finally {
                setLoading(false);
            }
        };

        initializeApp();
    }, []);

    if (loading) {
        return <div>Загрузка...</div>; // Показать индикатор загрузки
    }

    return (
        <div ref={appRef} className="App">
            <Router>
                {userStore.isAuthenticated && menuVisible && (
                    <div className="tile-menu">
                        <Link to="/statistics" className="tile" onClick={toggleMenu}>Статистика</Link>
                        <Link to="/management" className="tile" onClick={toggleMenu}>Управление</Link>
                        <Link to="/orders" className="tile" onClick={toggleMenu}>Заказы</Link>
                    </div>
                )}
                {!menuVisible && (
                    <div className="menu-toggle">
                        <button onClick={toggleMenu}>Меню</button>
                    </div>
                )}
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/dashboard" element={<PrivateRoute><EmptyScreen /></PrivateRoute>} />
                    <Route path="/statistics" element={<PrivateRoute><Statistics /></PrivateRoute>} />
                    <Route path="/management" element={<PrivateRoute><Management appRef={appRef}/></PrivateRoute>} />
                    <Route path="/orders" element={<PrivateRoute><Orders /></PrivateRoute>} />
                    <Route path="/" element={<Navigate to={userStore.isAuthenticated ? "/dashboard" : "/login"} replace />} />
                </Routes>
            </Router>
        </div>
    );
});

export default App;