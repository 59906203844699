import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import statisticsStore from '../../stores/statisticsStore';
import './Statistics.css';

const Statistics = observer(() => {
    const [viewPeriod, setViewPeriod] = useState('day');

    React.useEffect(() => {
        statisticsStore.fetchStatistics(viewPeriod);
    }, [viewPeriod]);

    const handleDayClick = (day) => {
        statisticsStore.fetchDayVisitors(day);
    };

    const handleUserClick = (userId) => {
        statisticsStore.fetchUserDetails(userId);
    };

    return (
        <div>
            <h2>Статистика</h2>
            
            <section>
                <h3>Активность</h3>
                {statisticsStore.selectedDay && (
                    <div>
                        <h4>Посетители за {statisticsStore?.selectedDay}</h4>
                        <ul>
                            {statisticsStore.selectedDayVisitors?.map(visitor => (
                                <li key={visitor?.id}>{visitor.name || 'Неизвестный'}</li>
                            )) || <li>Нет данных</li>}
                        </ul>
                    </div>
                )}
            </section>

            <section>
                <h3>Просмотры</h3>
                <select value={viewPeriod} onChange={(e) => setViewPeriod(e?.target?.value)}>
                    <option value="day">За сутки</option>
                    <option value="week">За неделю</option>
                    <option value="month">За месяц</option>
                </select>
                <ul>
                    {Array.isArray(statisticsStore.productViews) && statisticsStore.productViews.length > 0 ? (
                        statisticsStore.productViews.map(product => (
                            <li key={product?.id}>
                                {product?.name || 'Неизвестный продукт'}: {product?.views[viewPeriod] || 0} просмотров
                            </li>
                        ))
                    ) : (
                        <li>Нет данных</li>
                    )}
                </ul>
            </section>

            <section>
                <h3>Лайки</h3>
                <ul>
                    {Array.isArray(statisticsStore.topLikedProducts) && statisticsStore.topLikedProducts.length > 0 ? (
                        statisticsStore.topLikedProducts.map(product => (
                            <li key={product?.id}>
                                {product?.name || 'Неизвестный продукт'}: {product?.likes || 0} лайков
                            </li>
                        ))
                    ) : (
                        <li>Нет данных</li>
                    )}
                </ul>
            </section>

            <section>
                <h3>Рефералы</h3>
                <ul>
                    {Array.isArray(statisticsStore.topReferrers) && statisticsStore.topReferrers.length > 0 ? (
                        statisticsStore.topReferrers.map(referrer => (
                            <li key={referrer?.id} onClick={() => handleUserClick(referrer?.id)}>
                                {referrer?.name || 'Неизвестный'}: {referrer?.referrals || 0} рефералов
                            </li>
                        ))
                    ) : (
                        <li>Нет данных</li>
                    )}
                </ul>
                {statisticsStore?.selectedUserDetails && (
                    <div>
                        <h4>Рефералы пользователя {statisticsStore?.selectedUserDetails?.name || 'Неизвестный'}</h4>
                        <ul>
                            {Array.isArray(statisticsStore.selectedUserDetails.referrals) && statisticsStore.selectedUserDetails.referrals.length > 0 ? (
                                statisticsStore.selectedUserDetails.referrals.map(referral => (
                                    <li key={referral?.id}>{referral?.name || 'Неизвестный'}</li>
                                ))
                            ) : (
                                <li>Нет данных</li>
                            )}
                        </ul>
                    </div>
                )}
            </section>

            <section>
                <h3>Пользователи</h3>
                <ul>
                    {Array.isArray(statisticsStore.users) && statisticsStore.users.length > 0 ? (
                        statisticsStore.users.map(user => (
                            <li key={user?.id} onClick={() => handleUserClick(user?.id)}>
                                {user?.name || 'Неизвестный'} (ID: {user?.id})
                            </li>
                        ))
                    ) : (
                        <li>Нет данных</li>
                    )}
                </ul>
                {statisticsStore?.selectedUserDetails && (
                    <div>
                        <h4>Детали пользователя {statisticsStore?.selectedUserDetails?.name || 'Неизвестный'}</h4>
                        <p>Входы за неделю: {statisticsStore?.selectedUserDetails?.loginCountWeek || 0}</p>
                        <p>Входы за месяц: {statisticsStore?.selectedUserDetails?.loginCountMonth || 0}</p>
                        <p>Последний вход: {statisticsStore?.selectedUserDetails?.lastLogin || 'Неизвестно'}</p>
                        <h5>Избранные товары:</h5>
                        <ul>
                            {Array.isArray(statisticsStore.selectedUserDetails.favoriteProducts) && statisticsStore.selectedUserDetails.favoriteProducts.length > 0 ? (
                                statisticsStore.selectedUserDetails.favoriteProducts.map(product => (
                                    <li key={product?.id}>{product?.name || 'Неизвестный продукт'}</li>
                                ))
                            ) : (
                                <li>Нет данных</li>
                            )}
                        </ul>
                    </div>
                )}
            </section>
        </div>
    );
});

export default Statistics;