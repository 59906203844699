import { makeAutoObservable } from "mobx";
import $api from '../http';
import WebApp from '@twa-dev/sdk';

class UserStore {
    user = null;

    constructor() {
        makeAutoObservable(this);
    }

    setUser(user) {
        this.user = user;
    }

    async setTokens(accesstoken, refreshToken) {
        $api.defaults.headers.common['Authorization'] = `Bearer ${accesstoken}`;
        await WebApp.CloudStorage.setItem('accessToken', accesstoken);
        await WebApp.CloudStorage.setItem('refreshToken', refreshToken);
    }

    get isAuthenticated() {
        return !!this.user;
    }

    async checkAuthentication() {
        const accessToken = await WebApp.CloudStorage.getItem('accessToken');

        if (accessToken) {
            await $api.get('/user/test'); 
            const user = WebApp.initDataUnsafe?.user;
            this.setUser(user); 
        } else {
            this.clearUser();
        }
    }

    clearUser() {
        this.user = null;
    }

    async verifyPassword(password) {
        try {
            const response = await $api.post('/verify-password', { password });
            return response.data;
        } catch (error) {
            console.error('Password verification failed', error);
            throw error;
        }
    }
}

export default new UserStore();