import { makeAutoObservable, runInAction } from "mobx";
import $api from '../http';

class CategoriesStore {
    categories = [];

    constructor() {
        makeAutoObservable(this);
    }

    async fetchCategories() {
        try {
            const response = await $api.get('/categories/Get');
            runInAction(() => {
                this.categories = response.data;
            });
            return this.categories;
        } catch (error) {
            console.error('Failed to fetch categories', error);
        }
    }
}

export default new CategoriesStore();
