import $api from '../http/index';


export async function authenticateWithInitData(initData, password = 'pwd') {
    try {
        const response = await $api.post('/user/authenticate', {
            initData,
            password
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Authentication failed:', error);
        throw error;
    }
}

export async function setNewPassword(initData, password) {
    try {
        const response = await $api.post('/user/set-password', {
            initData,
            password
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Setting new password failed:', error);
        throw error;
    }
}