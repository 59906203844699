import { makeAutoObservable, runInAction } from "mobx";
import $api from '../http';

class StatisticsStore {
    activityData = { visits: [], uniqueUsers: [] };
    productViews = [];
    topLikedProducts = [];
    topReferrers = [];
    users = [];
    selectedDay = null;
    selectedDayVisitors = [];
    selectedUser = null;
    selectedUserDetails = null;

    constructor() {
        makeAutoObservable(this);
    }

    async fetchStatistics(period) {
        try {
            const response = await $api.get(`/statistics/common/${period}`);
            runInAction(() => {
                this.activityData = response.data.activityData;
                this.productViews = response.data.productViews;
                this.topLikedProducts = response.data.topLikedProducts;
                this.topReferrers = response.data.topReferrers;
                this.users = response.data.users;
            });
        } catch (error) {
            console.error('Failed to fetch statistics', error);
        }
    }

    async fetchDayVisitors(day) {
        try {
            const response = await $api.get(`/statistics/day-visitors/${day}`);
            runInAction(() => {
                this.selectedDay = day;
                this.selectedDayVisitors = response.data;
            });
        } catch (error) {
            console.error('Failed to fetch day visitors', error);
        }
    }

    async fetchUserDetails(userId) {
        try {
            const response = await $api.get(`/statistics/user-details/${userId}`);
            runInAction(() => {
                this.selectedUser = userId;
                this.selectedUserDetails = response.data;
            });
        } catch (error) {
            console.error('Failed to fetch user details', error);
        }
    }
}

export default new StatisticsStore();