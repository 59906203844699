import React from 'react';

const EmptyScreen = () => {
    return (
        <div className="empty-screen">
            <h1>Выберите пункт меню</h1>
        </div>
    );
};

export default EmptyScreen;