import { makeAutoObservable, runInAction } from "mobx";
import $api from '../http';

class OrdersStore {
    orders = [];

    constructor() {
        makeAutoObservable(this);
    }

    async fetchOrders() {
        try {
            const response = await $api.get('/orders');
            runInAction(() => {
                this.orders = response.data;
            });
        } catch (error) {
            console.error('Failed to fetch orders', error);
        }
    }

    async fetchOrderDetails(orderId) {
        try {
            const response = await $api.get(`/orders/${orderId}`);
            runInAction(() => {
                const index = this.orders.findIndex(order => order.Id === orderId);
                if (index !== -1) {
                    this.orders[index] = response.data;
                }
            });
        } catch (error) {
            console.error('Failed to fetch order details', error);
        }
    }

    async updateOrderStatus(orderId, field) {
        try {
            const order = this.orders.find(order => order.Id === orderId);
            const updatedStatus = !order[field];
            await $api.patch(`/orders/${orderId}`, { [field]: updatedStatus });
            runInAction(() => {
                order[field] = updatedStatus;
            });
        } catch (error) {
            console.error('Failed to update order status', error);
        }
    }

    async deleteOrder(orderId) {
        try {
            await $api.delete(`/orders/${orderId}`);
            runInAction(() => {
                this.orders = this.orders.filter(order => order.Id !== orderId);
            });
        } catch (error) {
            console.error('Failed to delete order', error);
        }
    }
}

export default new OrdersStore();
