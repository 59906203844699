import { makeAutoObservable, runInAction } from "mobx";
import $api from '../http';

class DashboardStore {
    totalProducts = 0;
    activeOrders = 0;
    monthlySales = 0;
    newUsers = 0;
    salesData = [];
    topProducts = [];
    recentOrders = [];
    notifications = [];

    constructor() {
        makeAutoObservable(this);
    }

    async fetchDashboardData() {
        try {
            const response = await $api.get('/dashboard');
            runInAction(() => {
                this.totalProducts = response.data.totalProducts;
                this.activeOrders = response.data.activeOrders;
                this.monthlySales = response.data.monthlySales;
                this.newUsers = response.data.newUsers;
                this.salesData = response.data.salesData;
                this.topProducts = response.data.topProducts;
                this.recentOrders = response.data.recentOrders;
                this.notifications = response.data.notifications;
            });
        } catch (error) {
            console.error('Failed to fetch dashboard data', error);
        }
    }
}

export default new DashboardStore();
