import { makeAutoObservable, runInAction } from "mobx";
import $api from '../http';

class ManagementStore {
    products = [];
    selectedProductDetails = null;

    constructor() {
        makeAutoObservable(this);
    }

    async fetchProducts() {
        try {
            const response = await $api.get('/products');
            runInAction(() => {
                this.products = response.data;
            });
            console.log(this.products);
        } catch (error) {
            console.error('Failed to fetch products', error);
        }
    }

    async fetchProductDetails(productId) {
        try {
            const response = await $api.get(`/products/${productId}`);
            runInAction(() => {
                this.selectedProductDetails = response.data;
            });
        } catch (error) {
            console.error('Failed to fetch product details', error);
        }
    }

    async updateProduct(productId, product) {
        try {
            const response = await $api.put(`/products/${productId}`, product);
            await this.fetchProductDetails(productId);
            return response;
        } catch (error) {
            console.error('Failed to update product', error);
        }
    }
}

export default new ManagementStore();
