import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import categoriesStore from '../../stores/categoriesStore';
import managementStore from '../../stores/managementStore';

const ProductDetails = observer(({ product, onClose }) => {
    const [editedProduct, setEditedProduct] = useState(product);
    const [changedFields, setChangedFields] = useState({});
    const [categories, setCategories] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [showSuccess, setShowSuccess] = useState(false); // Состояние для галочки

    useEffect(() => {
        const fetchCategories = async () => {
            const fetchedCategories = await categoriesStore.fetchCategories();
            setCategories(fetchedCategories);
        };
        fetchCategories();
    }, []);

    const handleChange = (field, value) => {
        setEditedProduct({ ...editedProduct, [field]: value });
        setChangedFields({ ...changedFields, [field]: true });
    };

    const handleSave = async () => {
        console.log('Сохранение данных...'); // Для отладки
        console.log('editedProduct:', editedProduct); // Логирование Id продукта
        if (editedProduct.id) {
            const response = await managementStore.updateProduct(editedProduct.id, editedProduct);
            if (response && (response.status === 200 || response.status === 204)) {
                setShowSuccess(true); // Показываем галочку
                setTimeout(() => setShowSuccess(false), 2000); // Скрываем через 2 секунды
            }
            setChangedFields({});
        }
    };

    const nextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % editedProduct.photoUrls.length);
    };

    const prevImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + editedProduct.photoUrls.length) % editedProduct.photoUrls.length);
    };

    return (
        <div className="product-details">
            <button onClick={onClose}>Закрыть</button>
            {showSuccess && (
                <div className="success-message">✅</div> // Галочка
            )}
            {editedProduct && (
                <>
                    <div>
                        <label>Артикул:</label>
                        <input type="text" value={editedProduct.article} readOnly />
                    </div>
                    <div>
                        <label>Категория:</label>
                        <select
                            value={editedProduct.categoryName}
                            onChange={(e) => handleChange('categoryName', e.target.value)}
                        >
                            {categories.map(category => (
                                <option key={category.id} value={category.name}>{category.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="image-slider">
                        <button className="arrow-button" onClick={prevImage} disabled={editedProduct.photoUrls.length <= 1}>
                            &#9664; {/* Стрелка влево */}
                        </button>
                        <img src={editedProduct.photoUrls[currentImageIndex]} alt={`Фото ${currentImageIndex + 1}`} />
                        <button className="arrow-button" onClick={nextImage} disabled={editedProduct.photoUrls.length <= 1}>
                            &#9654; {/* Стрелка вправо */}
                        </button>
                    </div>
                    <div>
                        <label>Название:</label>
                        <input
                            type="text"
                            value={editedProduct.name || ''}
                            onChange={(e) => handleChange('name', e.target.value)}
                            style={{ borderColor: changedFields.name ? 'lightgreen' : 'white' }}
                        />
                    </div>
                    <div>
                        <label>Производитель:</label>
                        <input
                            type="text"
                            value={editedProduct.producerName || ''}
                            onChange={(e) => handleChange('producerName', e.target.value)}
                            style={{ borderColor: changedFields.producerName ? 'lightgreen' : 'white' }}
                        />
                    </div>
                    <div>
                        <label>Цена:</label>
                        <input
                            type="text"
                            value={editedProduct.price || ''}
                            onChange={(e) => handleChange('price', e.target.value)}
                            style={{ borderColor: changedFields.price ? 'lightgreen' : 'white' }}
                        />
                    </div>
                    <div>
                        <label>Количество:</label>
                        <input
                            type="text"
                            value={editedProduct.amount || ''}
                            onChange={(e) => handleChange('amount', e.target.value)}
                            style={{ borderColor: changedFields.amount ? 'lightgreen' : 'white' }}
                        />
                    </div>
                    <div>
                        <label>Цвет:</label>
                        <input
                            type="text"
                            value={editedProduct.color || ''}
                            onChange={(e) => handleChange('color', e.target.value)}
                            style={{ borderColor: changedFields.color ? 'lightgreen' : 'white' }}
                        />
                    </div>
                    <div>
                        <label>Размер:</label>
                        <input
                            type="text"
                            value={editedProduct.size || ''}
                            onChange={(e) => handleChange('size', e.target.value)}
                            style={{ borderColor: changedFields.size ? 'lightgreen' : 'white' }}
                        />
                    </div>
                    <div>
                        <label>Описание:</label>
                        <textarea
                            value={editedProduct.description || ''}
                            onChange={(e) => handleChange('description', e.target.value)}
                            style={{ borderColor: changedFields.description ? 'lightgreen' : 'white' }}
                        />
                    </div>
                    <div>
                        <label>Страна происхождения:</label>
                        <input
                            type="text"
                            value={editedProduct.countryOfOrigin || ''}
                            onChange={(e) => handleChange('countryOfOrigin', e.target.value)}
                            style={{ borderColor: changedFields.countryOfOrigin ? 'lightgreen' : 'white' }}
                        />
                    </div>
                    <div>
                        <label>Вес:</label>
                        <input
                            type="text"
                            value={editedProduct.weight || ''}
                            onChange={(e) => handleChange('weight', e.target.value)}
                            style={{ borderColor: changedFields.weight ? 'lightgreen' : 'white' }}
                        />
                    </div>
                    <div>
                        <label>Габариты:</label>
                        <input
                            type="text"
                            value={editedProduct.dimensions || ''}
                            onChange={(e) => handleChange('dimensions', e.target.value)}
                            style={{ borderColor: changedFields.dimensions ? 'lightgreen' : 'white' }}
                        />
                    </div>
                    {Object.keys(changedFields).length > 0 && (
                        <button onClick={handleSave}>Сохранить</button>
                    )}
                </>
            )}
        </div>
    );
});

export default ProductDetails;