import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import managementStore from '../../stores/managementStore';

import ProductDetails from './ProductDetails';
import './Management.css';

const Management = observer(({ appRef }) => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [filters, setFilters] = useState({
        category: '',
        producer: '',
        size: '',
        color: ''
    });
    const [visibleProducts, setVisibleProducts] = useState([]);
    const [endIndex, setEndIndex] = useState(12);

    const filteredProducts = managementStore?.products.filter(product => {
        return (
            (!filters?.category || product?.categoryName === filters?.category) &&
            (!filters?.producer || product?.producerName === filters?.producer) &&
            (!filters?.size || (filters.size === "Без размера" ? !product?.size : product?.size === filters?.size)) &&
            (!filters?.color || (filters.color === "Без цвета" ? !product?.color : product?.color === filters?.color))
        );
    });

    useEffect(() => {
        managementStore.fetchProducts();
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (
                appRef.current &&
                appRef.current.scrollHeight - appRef.current.scrollTop <= appRef.current.clientHeight + 50
            ) {
                console.log('scroll');
                setEndIndex(prevIndex => prevIndex + 12);
            }
        };

        if (appRef.current) {
            appRef.current.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (appRef.current) {
                appRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [appRef]);

    useEffect(() => {
        setVisibleProducts(filteredProducts.slice(0, endIndex));
    }, [filteredProducts, endIndex]);

    const handleFilterChange = (filterType, value) => {
        setFilters({ ...filters, [filterType]: value });
        setEndIndex(12); // Сбросить количество видимых продуктов при изменении фильтров
    };

    const handleProductClick = (product) => {
        setSelectedProduct(product);
    };

    return (
        <div className="management-container">
            <div className="filters">
                <select onChange={(e) => handleFilterChange('category', e?.target?.value)}>
                    <option value="">Все категории</option>
                    {Array.from(new Set(managementStore.products.map(product => product.categoryName))).sort().map(category => (
                        <option key={category} value={category}>{category}</option>
                    ))}
                </select>
                <select onChange={(e) => handleFilterChange('producer', e?.target?.value)}>
                    <option value="">Все производители</option>
                    {Array.from(new Set(managementStore.products.map(product => product.producerName))).sort().map(producer => (
                        <option key={producer} value={producer}>{producer}</option>
                    ))}
                </select>
                <select onChange={(e) => handleFilterChange('size', e?.target?.value)}>
                    <option value="">Все размеры</option>
                    <option value="Без размера">Без размера</option>
                    {Array.from(new Set(managementStore.products.map(product => product.size))).sort().map(size => (
                        size ? <option key={size} value={size}>{size}</option> : null
                    ))}
                </select>
                <select onChange={(e) => handleFilterChange('color', e?.target?.value)}>
                    <option value="">Все цвета</option>
                    <option value="Без цвета">Без цвета</option>
                    {Array.from(new Set(managementStore.products.map(product => product.color))).sort().map(color => (
                        color ? <option key={color} value={color}>{color}</option> : null
                    ))}
                </select>
            </div>
            <div className="product-list">
                {visibleProducts.map(product => (
                    <div key={product?.Id} className="product-item" onClick={() => handleProductClick(product)}>
                        <img src={product?.photoUrls[0]} alt={product?.name} />
                        <div className="product-list-description">
                            <p className="product-list-article">{product?.article}</p>
                            <p className="product-list-name">{product?.name}</p>
                        </div>
                    </div>
                ))}
            </div>
            {selectedProduct && (
                <ProductDetails 
                    product={selectedProduct} 
                    onClose={() => setSelectedProduct(null)}
                />
            )}
        </div>
    );
});

export default Management;